import React, {useState} from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Tabs from '../../components/tabs'
import PersonalInformation from '../../components/profile/personalInformation'
import ProviderProposals from '../../components/provider-profile/providerProposals'
import ProviderJobs from '../../components/provider-profile/providerJobs'
import { useSelector } from 'react-redux'
import * as styles from './styles.module.css'

const Profile = ({location}) => {
  const login =  useSelector(state => state.userReducer.login.response)
  const initialTabs= {'Perfil Proveedor': true, 'Mis Trabajos': false, 'Mis Propuestas': false}
  if(location.state && location.state.tab) { 
    Object.keys(initialTabs).forEach(key =>  initialTabs[key] = key === location.state.tab)
  }


  const [tabs, setTabs] = useState(initialTabs)
  
  const onChangeTab = tab => {
    const updatedTabs = {}
    Object.keys(tabs).forEach(key =>  updatedTabs[key] = key === tab)
    setTabs(updatedTabs)
  }

  return (
    <Layout>
      <SEO title='Perfil' />
      <div className={styles.profileContainer}>
        <h1>{`Hola ${login && login.name || ''}`}</h1> 
        <Tabs tabs={tabs} onChangeTab={onChangeTab}/>
        {tabs['Perfil Proveedor'] && <PersonalInformation profile='provider' />}
        {tabs['Mis Trabajos'] && login && <ProviderJobs/>}
        {tabs['Mis Propuestas'] && login && <ProviderProposals/>}
      </div>
    </Layout>
  )
}

export default Profile
