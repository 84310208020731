import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import moment from 'moment'
import ListItem from '../../listItem'
import { get, getAndConcat } from '../../../reducers/actions/proposal.js'
import * as styles from './styles.module.css'

const PersonalProposals = () => {
  const proposals = useSelector(state => state.proposalReducer.getProposals)
  const user = useSelector(state => state.userReducer.login.response)
  const dispatch = useDispatch()

  const [isFirstRender, setIsFirstRender] = useState(true)
  const queryParams = {
    userId: user._id,
    page: 1,
    limit: 40
  }

  useEffect(() => {
    if(isFirstRender) {
      get(dispatch)(queryParams)
      setIsFirstRender(false)
    }
  })

  const loadDocuments = entry => {
    if (entry.intersectionRatio > 0) {
      if(
        !proposals.loading &&
        proposals.response &&
        proposals.response.totalPages > proposals.response.page
      ) {
        getAndConcat(dispatch)({...queryParams, page: proposals.response.page+1})
        }
    }  
  }

  const observer =  new IntersectionObserver(entries => entries.forEach(loadDocuments))
  const scrollObserver = useCallback(node => observer.observe(node), getAndConcat(dispatch))
  const boundaryRef = useRef(null);
  
  useEffect(() => {
    if (boundaryRef.current) {
      scrollObserver(boundaryRef.current)
    }
  }, [proposals, boundaryRef])

  const list = proposals.response ? proposals.response.docs.map(proposal => ({
    title: proposal.project.name,
    text: proposal.project.summary,
    highlights: {
      'Precio': proposal.price,
      'Ciere de postulaciones': moment(proposal.deadline).format('DD-MM-YYYY'),
      'Estatus': proposal.publish ? 'Publicado': 'Borrador'
    },
    actions: [
      {text: 'editar', callback: () => navigate(`/proposal?id=${proposal._id}`)},
      {text: 'borrar', callback: () => {}}
     ]
  })): []

  const {page, limit} = proposals.response ? proposals.response : queryParams
  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {list.map((proposal, index) => (
          <React.Fragment key={index}>
            <ListItem key={index} {...proposal} />
            {index === (page*limit/2) && <div ref={boundaryRef}></div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

export default PersonalProposals
