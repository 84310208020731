import React from 'react'
import UserInformation from './userInformation.js'
import ContactInformation from './contactInformation.js'
import ChangePassword from './changePassword.js'
import ProviderDescription from './providerDescription.js'
import ProviderServices from './providerServices.js'

const PersonalInformation = ({profile}) => {
  return (
    <div>
      <UserInformation />
      <ContactInformation
        fieldToUpdate={profile ==='provider' ? 'provider' : 'contact'}
      />
      {profile === 'provider' && <ProviderDescription />}
      {profile === 'provider' && <ProviderServices />}
      <ChangePassword />
    </div>
  )
}

export default PersonalInformation
