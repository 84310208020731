import {
  create as createProposal,
  getById as getProposalById,
  get as getProposals,
  getAndConcat as getProposalsAndConcat,
  update as updateProposal
} from '../types/proposal.js';

const baseURL = process.env.GATSBY_API_URL;

const fetchInstance = async (url, options = {}) => {
  const response = await fetch(`${baseURL}${url}`, {
    credentials: "include",
    ...options,
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const create = dispatch => async (proposal) => {
  dispatch({ type: createProposal.REQUEST });
  try {
    const data = await fetchInstance('/proposal', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(proposal),
    });
    dispatch({ type: createProposal.SUCCESS, data });
  } catch (error) {
    dispatch({ type: createProposal.FAIL });
  }
};

export const getById = dispatch => async (proposalId) => {
  dispatch({ type: getProposalById.REQUEST });
  try {
    const data = await fetchInstance(`/proposal/${proposalId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({ type: getProposalById.SUCCESS, data });
  } catch (error) {
    dispatch({ type: getProposalById.FAIL });
  }
};

export const get = dispatch => async (queryParams) => {
  const queryString = Object.keys(queryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');
  
  dispatch({ type: getProposals.REQUEST });
  try {
    const data = await fetchInstance(`/proposal?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({ type: getProposals.SUCCESS, data });
  } catch (error) {
    dispatch({ type: getProposals.FAIL });
  }
};

export const getAndConcat = dispatch => async (queryParams) => {
  const queryString = Object.keys(queryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');
  
  dispatch({ type: getProposalsAndConcat.REQUEST });
  try {
    const data = await fetchInstance(`/proposal?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({ type: getProposalsAndConcat.SUCCESS, data });
  } catch (error) {
    dispatch({ type: getProposalsAndConcat.FAIL });
  }
};

export const update = dispatch => async (proposal) => {
  dispatch({ type: updateProposal.REQUEST });
  try {
    const data = await fetchInstance('/proposal', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(proposal),
    });
    dispatch({ type: updateProposal.SUCCESS, data });
  } catch (error) {
    dispatch({ type: updateProposal.FAIL });
  }
};
