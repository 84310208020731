import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as styles from './styles.module.css'
import { update } from '../../../reducers/actions/user.js'

const ProviderDescription = () => {
  const user = useSelector(state => state.userReducer.login.response)
  const dispatch = useDispatch()
  const [form, setForm] = useState({description: ''})
  
  const bindForm = (event) => setForm({...form, [event.target.name]: event.target.value})
  const updateUser = () => {
    const payload = { provider: { ...user.provider, ...form }, _id: user._id}
    update(dispatch)(payload)
  }

  useEffect(() => {
    if(user && user.provider) {
      setForm({
        description: user.provider.description
      })
    }
  }, [user])

 return (
    <div className={styles.userInformation}>
      <h3>Descripción</h3>
      <div className={styles.card}>
        <label>Describe tus servicios</label>
        <textarea name='description' value={form.description} onChange={bindForm} row={5} placeholder=''/>
        <button onClick={updateUser}>Actualizar</button>
      </div>
   </div>
  )
}

export default ProviderDescription
