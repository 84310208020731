import React from 'react'
import * as styles from './styles.module.css'

const ChangePassword = () => {
  return (
    <div className={styles.userInformation}>
      <h3>Contraseña</h3>
      <button>Cambiar contraseña</button>
    </div>
  )
}

export default ChangePassword
