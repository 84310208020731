import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as styles from './styles.module.css'
import { update } from '../../../reducers/actions/user.js'

const UserInformation = () => {
  const user = useSelector(state => state.userReducer.login.response)
  const dispatch = useDispatch()
  const [form, setForm] = useState({name: '', email: ''})
  
  const bindForm = (event) => setForm({...form, [event.target.name]: event.target.value})
  const updateUser = () => {
    const payload = { ...form, _id: user._id}
    update(dispatch)(payload)
  }
  useEffect(() => {
    if(user) {
      setForm({name: user.name, email: user.email})
    }
  }, [user])

  return (
    <div className={styles.userInformation}>
      <h3>Información básica</h3>
      <div className={styles.card}>
        <input name='name' value={form.name} onChange={bindForm} placeholder='Nombre'/>
        <input name='email' value={form.email} onChange={bindForm} placeholder='Email de la cuenta'/>
        <button onClick={updateUser}>Actualizar Datos</button>
      </div>
    </div>
  )
}

export default UserInformation
